html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  margin: 0;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.2;
}

h1 {
  line-height: .825;
}

small {
  font-size: 70%;
}

/**
 * Links
 */

a, .link {
  text-decoration: none;
  transition: all 0.14s ease;
}

a:hover, .link:hover {
  cursor: pointer;
}

a:active, .link:active {
}


/**
 * Animations
 */

.animateFadeIn {
  animation: fadeIn 0.45s;
}

.animateScaleIn {
  animation: scaleIn 0.35s;
}

.animateFlicker {
  animation: flicker 2.5s infinite;
}

.pointer {
  cursor: pointer !important;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8); 
  }
  to   { 
    opacity: 1;
    transform: scale(1); 
  }
}

@keyframes flicker {
	0% {
		opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
	100% {
		opacity: 0.3;
	}
}