/*!
 * Branched from:
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
	font-family: 'IAS-Icons';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url('./IAS-Icons-Light.woff2') format('woff2'),
		url('./IAS-Icons-Light.woff') format('woff');
}
.iasi:after,
.iasi:before {
	font-family: 'IAS-Icons';
	font-weight: 300;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.ic-lg {
	font-size: 1.33333em;
	line-height: 0.75em;
	vertical-align: -.0667em;
}

.ic-xs {
	font-size: .75em;
}

.ic-sm {
	font-size: .875em;
}

.ic-1x {
	font-size: 1em;
}

.ic-2x {
	font-size: 2em;
}

.ic-3x {
	font-size: 3em;
}

.ic-4x {
	font-size: 4em;
}

.ic-5x {
	font-size: 5em;
}

.ic-6x {
	font-size: 6em;
}

.ic-7x {
	font-size: 7em;
}

.ic-8x {
	font-size: 8em;
}

.ic-9x {
	font-size: 9em;
}

.ic-10x {
	font-size: 10em;
}

.ic-fw {
	text-align: center;
	width: 1.25em;
}

.ic-ul {
	list-style-type: none;
	margin-left: 2.5em;
	padding-left: 0;
}
.ic-ul > li {
	position: relative;
}

.ic-li {
	left: -2em;
	position: absolute;
	text-align: center;
	width: 2em;
	line-height: inherit;
}

.ic-border {
	border: solid 0.08em #eee;
	border-radius: .1em;
	padding: .2em .25em .15em;
}

.ic-pad-right::before,
.ic-pad-right::after {
	margin-left: .3em;
}
.ic-pad-left::before,
.ic-pad-left::after {
	margin-right: .3em;
}

.ic-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

.ic-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.ic-rotate-45,
.ic-rotate-45:before,
.ic-rotate-45:after {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.ic-rotate-90 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.ic-rotate-180 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ic-rotate-270 {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

.ic-flip-horizontal {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
	-webkit-transform: scale(-1, 1);
	transform: scale(-1, 1);
}

.ic-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
	-webkit-transform: scale(1, -1);
	transform: scale(1, -1);
}

.ic-flip-both,
.ic-flip-horizontal.ic-flip-vertical {
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
	-webkit-transform: scale(-1, -1);
	transform: scale(-1, -1);
}

:root.ic-rotate-45:before,
:root.ic-rotate-90:before,
:root.ic-rotate-180:before,
:root.ic-rotate-270:before,
:root.ic-flip-horizontal:before,
:root.ic-flip-vertical:before,
:root.ic-flip-both:before,
:root.ic-rotate-45:after,
:root.ic-rotate-90:after,
:root.ic-rotate-180:after,
:root.ic-rotate-270:after,
:root.ic-flip-horizontal:after,
:root.ic-flip-vertical:after,
:root.ic-flip-both:after {
	-webkit-filter: none;
	filter: none;
}

.ic-stack:before,
.ic-stack:after {
	display: inline-block;
	height: 2em;
	line-height: 2em;
	position: relative;
	vertical-align: middle;
	width: 2.5em;
}

.ic-stack-1x:before,
.ic-stack-1x:after,
.ic-stack-2x:before,
.ic-stack-2x:after {
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
}

.ic-stack-1x:before,
.ic-stack-1x:after {
	line-height: inherit;
}

.ic-stack-2x:before,
.ic-stack-2x:after {
	font-size: 2em;
}

// Custom mixing for before and after styles
// Build components with our theme
$ic-prepositions: ("bf": "before", "af": "after");

@mixin preposition($content) {
	@each $pos, $value in $ic-prepositions {
		&-#{$pos} {
			&:#{$value} {
				content: #{'"\\' + $content + '"'};
			}
		}
	}
}

// Custom layout styles
.ic-flex {
	display: flex;
	align-items: center;
	&:after,
	&:before {
		height: 1rem;
		line-height: 1rem;
	}
}
.ic-sb {
	justify-content: space-between !important;
}

.ic-hide > i {
	display: none;
}

// Icons:
.ic-cloud-download {
	@include preposition("f019");
}

.ic-redo {
	@include preposition("f01e");
}

.ic-sync {
	@include preposition("f021");
}

.ic-print {
	@include preposition("f02f");
}

.ic-chevron-left {
	@include preposition("f053");
}

.ic-chevron-right {
	@include preposition("f054");
}

.ic-chevron-down {
	@include preposition("f078");
}

.ic-chevron-up {
	@include preposition("f077");
}

.ic-info-circle {
	@include preposition("f05a");
}

.ic-search-plus {
	@include preposition("f00e");
}

.ic-ban {
	@include preposition("f05e");
}

.ic-long-arrow-down {
	@include preposition("f175");
}

.ic-long-arrow-left {
	@include preposition("f177");
}

.ic-long-arrow-right {
	@include preposition("f178");
}

.ic-long-arrow-up {
	@include preposition("f176");
}

.ic-arrow-left {
	@include preposition("f060");
}

.ic-arrow-right {
	@include preposition("f061");
}

.ic-arrow-up {
	@include preposition("f062");
}

.ic-arrow-down {
	@include preposition("f063");
}

.ic-plus {
	@include preposition("f067");
}

.ic-minus {
	@include preposition("f068");
}

.ic-plane {
	@include preposition("f072");
}

.ic-shopping-cart {
	@include preposition("f07a");
}

.ic-external-link {
	@include preposition("f08e");
}

.ic-bars {
	@include preposition("f0c9");
}

.ic-caret-down {
	@include preposition("f0d7");
}

.ic-caret-left {
	@include preposition("f0d9");
}

.ic-caret-right {
	@include preposition("f0da");
}

.ic-caret-up {
	@include preposition("f0d8");
}

.ic-envelope {
	@include preposition("f0e0");
}

.ic-exchange {
	@include preposition("f0ec");
}

.ic-level-up {
	@include preposition("f148");
}

.ic-level-down {
	@include preposition("f149");
}

.ic-long-arrow-down {
	@include preposition("f175");
}

.ic-long-arrow-left {
	@include preposition("f177");
}

.ic-long-arrow-right {
	@include preposition("f178");
}

.ic-long-arrow-up {
	@include preposition("f176");
}

.ic-user-circle {
	@include preposition("f2bd");
}

.ic-info-square {
	@include preposition("f30f");
}

.ic-chevron-double-down {
	@include preposition("f322");
}

.ic-chevron-double-left {
	@include preposition("f323");
}

.ic-chevron-double-right {
	@include preposition("f324");
}

.ic-chevron-double-up {
	@include preposition("f325");
}

.ic-globe-africa {
	@include preposition("f57c");
}

.ic-globe-americas {
	@include preposition("f57d");
}

.ic-globe-asia {
	@include preposition("f57e");
}

.ic-globe-europe {
	@include preposition("f7a2");
}

.ic-plane-arrival {
	@include preposition("f5af");
}

.ic-plane-departure {
	@include preposition("f5b0");
}

.ic-lightbulb-on {
	@include preposition("f672");
}

.ic-ellipsis-h {
	@include preposition("f141");
}

.ic-times {
	@include preposition("f00d");
}

.ic-file-pdf {
	@include preposition("f1c1");
}

// OCI Icons

.ic-oci-stun {
	@include preposition("ED01");
}
.ic-oci-battery {
	@include preposition("ED02");
}
.ic-oci-explosives {
	@include preposition("ED03");
}
.ic-oci-sharpitems {
	@include preposition("ED04");
}
.ic-oci-blunt {
	@include preposition("ED05");
}
.ic-oci-paint {
	@include preposition("ED06");
}
.ic-oci-fuel {
	@include preposition("ED07");
}
.ic-oci-firearms {
	@include preposition("ED08");
}
.ic-oci-fireworks {
	@include preposition("ED09");
}
.ic-oci-tools {
	@include preposition("ED10");
}
.ic-oci-extinguisher {
	@include preposition("ED11");
}
.ic-oci-extinguisher {
	@include preposition("ED12");
}

