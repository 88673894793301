.airportIata {
  font-size: 3rem;
  font-weight: 300;
}

.barcode {
  width: 100%;
  max-height: 500px;
  max-width: 500px;
  height: auto;
}

.card {
  border-radius: 0.75rem;
  width: 19rem;
  margin: 0 auto;
}

.skeleton {
  border-radius: 0.75rem !important;
}

.logo {
  width: 150px;
}

.plane {
  font-size: 1.75rem;
  /* font-size: calc(1rem + 1.5vw); */
}

[data-columns="1"] {
  padding: 0 1rem;
  grid-template-columns: 1fr !important;
  /* display: flex !important; */
}
.passes {
  display: flex;
  
  grid-gap: 0;
	overflow-x: auto !important;
	scroll-snap-type: x mandatory;

	scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  grid-template-columns: repeat(10, 82vw);
  
  &.mobile {
    display: grid !important;
    .child {
      /* width: 200px !important; */
      padding: 0.5rem !important;
    }
  }

	/* &::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: black;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	} */
}