/* $white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
); */

$primary:      	#0D477B; 
$secondary:     #083962;
$info:          #3D6C95;
/* $success:       $green !default;
$warning:       $yellow !default;
$danger:        $red !default; */
$light:         #F7F8F9;
$medium:        #DEDEDE;
$dark:          #2E3338;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "info":       $info,
    /* "success":    $success,
    "warning":    $warning,
    "danger":     $danger, */
    "light":      $light,
    "medium":     $medium,
    "dark":       $dark
  ),
  $theme-colors
);

// Enable responsive font sizes to help fluid layouts be proportionate without crazy media query definitions
/* $enable-responsive-font-sizes: true; */

// add our custom icon family
@import './assets/icons/icons.scss';

// We can decide what pieces of Bootstrap to import, this can save us file size for uneeded pieces

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
/* @import "~bootstrap/scss/code"; */
@import "~bootstrap/scss/grid";
/* @import "~bootstrap/scss/tables"; */
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
/* @import "~bootstrap/scss/input-group"; */
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
/* @import "~bootstrap/scss/breadcrumb"; */
/* @import "~bootstrap/scss/pagination"; */
/* @import "~bootstrap/scss/badge"; */
/* @import "~bootstrap/scss/jumbotron"; */
@import "~bootstrap/scss/alert";
/* @import "~bootstrap/scss/progress"; */
/* @import "~bootstrap/scss/media"; */
@import "~bootstrap/scss/list-group";
/* @import "~bootstrap/scss/close"; */
/* @import "~bootstrap/scss/toasts"; */
@import "~bootstrap/scss/modal";
/* @import "~bootstrap/scss/tooltip"; */
/* @import "~bootstrap/scss/popover"; */
/* @import "~bootstrap/scss/carousel"; */
/* @import "~bootstrap/scss/spinners"; */
@import "~bootstrap/scss/utilities";
/* @import "~bootstrap/scss/print"; */




// Define type system
$t8pt-type-system: (
	"40" : 2.5rem,
	"32" : 2rem,
	"24" : 1.5rem,
	"20" : 1.25rem,
	"16" : 1rem,
	"14" : 0.875rem,
	"12" : 0.75rem,
	"10" : 0.625rem,
	"8" : 0.5rem
);

// Build type system
@each $size, $value in $t8pt-type-system {
	.fs-#{$size} {
		@include font-size($value);
	}
}

.dropdown-toggle::after {
  display: none;
}

.terms-icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  bottom: 0.25rem;
  right: -2.25rem;
}

header {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1040;
}

.loader--inner-circle {
  stroke: var(--secondary);
}

.loader--outer-circle {
  stroke: var(--primary);
}

.loadingSpinner {
  position: absolute; left: 50%; top: 50%; z-index: 9999; margin-left: -100px; margin-top: -125px;
}